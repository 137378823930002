@mixin centerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin centerPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin centerX {
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
}
@mixin centerY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@mixin xs {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin sm {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 992px) {
    @content;
  }
}
@mixin md {
  @media (max-width: 1280px) {
    @content;
  }
}
@mixin lg {
  @media (max-width: 1450px) {
    @content;
  }
}
@mixin xl {
  @media (max-width: 1700px) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: 1701px) {
    @content;
  }
}
@mixin flex($align, $justify, $Direction) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $Direction;
 
}
@mixin text-gradient {
  background: linear-gradient(123.17deg, #00FFB4 0%, #0F2FFF 112.86%), linear-gradient(180deg, #8991FF 0%, #3A48FD 99.99%, rgba(67, 80, 255, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
}
@mixin text-gradient2 {
  background: linear-gradient(180deg, #BBC0FF 0%, #3A48FD 99.99%, rgba(255, 255, 255, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
}
@mixin gradientbtn {
  background: linear-gradient(104.42deg, #00FFB4 0%, #0F2FFF 60.23%);
  
}
@mixin linearGradient1{
  background: linear-gradient(to right, #060714, #0D0F2B);
}
@mixin linearGradient2{
  background: linear-gradient(180deg, #2A27C9 0%, #00EAF9 100%);
}
@mixin linearGradient3 {
  background: linear-gradient(180deg, #2C2C2C 0%, #000000 100%);
  
}
@mixin MakeGradientOverlay{
  &> div{
    position: relative;
    z-index: 2;
}
&::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: radial-gradient( #180135,#180135,#180135);
    filter: blur(323px);
}
}