@use "./variables" as *;
@use "./Mixins" as *;

@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
aside,
figure,
figcaption,
ul,
address {
  margin: 0;
  padding: 0;
}

html{
  scroll-behavior: smooth;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Urbanist', sans-serif;
  list-style: none;

}

ul {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}


input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(255, 255, 255);
  background: transparent !important;

}

select {
  background: transparent;
}

button {
  background: transparent;
  cursor: pointer;
  @include centerFlex;
}



@media screen and(min-width:1850px) {
  .container {
    margin-left: auto;
    margin-right: auto;
     max-width:1760px !important;
   }

}


@media screen and (min-width:1500px) and(max-width:1800px) {
  .container {
   margin-left: auto;
   margin-right: auto;
    max-width:1430px !important; 
  }
  
}
@media screen  and(max-width:1450px) {
  .container {
    margin-left: auto;
    margin-right: auto;
     max-width:1370px !important;
   }

}
@media screen and(max-width:1370px) {
  .container {
    margin-left: auto;
    margin-right: auto;
     max-width:1260px !important;
   }

}
@media screen and(max-width:1280px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    width:94vw !important;
   }

}
@media screen and(max-width:1100px) {
   .container{
    width: 96vw;
   }
}